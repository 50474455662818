import React, {useState} from 'react';
import './App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {
    Button,
    createTheme,
    IconButton,
    InputAdornment,
    TextField,
    ThemeProvider
} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Visibility, VisibilityOff, Lock} from "@mui/icons-material";
import {FormControl} from '@mui/material';
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {object, string} from "yup";
import axios from "axios";
import {useNavigate} from "react-router-dom";

type LoginForm = {
    Nome: string;
    Password: string;
}

const URL = "https://sviluppo.infoweb-ti.com/"

function App() {

    const [showPassword, setShowPassword] = useState(true);
    const [error, setError] = useState('')

    const validationSchema = object({
        Nome: string()
            .required("Inserire il proprio nome utente."),
        Password: string().required('Inserire la password.'),
    }).required();

    const {
        control,
        handleSubmit,
        clearErrors,
        formState: {errors},
    } = useForm<LoginForm>({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
    });


    const theme = createTheme({
        typography: {
            fontFamily: [
                'Poppins',
            ].join(",")
        },
    });


    const img1 = "https://images.unsplash.com/photo-1449157291145-7efd050a4d0e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
    const img2 = "https://images.unsplash.com/photo-1490644658840-3f2e3f8c5625?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
    const img3 = "https://images.unsplash.com/photo-1470723710355-95304d8aece4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
    const logo = "https://delivery.infoweb-ti.com/img/samples/LogoCompany.png"


    const styles = {
        input: {
            marginTop: 5,
            marginBottom: 5,
            fontFamily: 'Poppins'
        },
        button: {
            backgroundColor: '#22bbed',
            marginTop: 20,
            fontFamily: 'Poppins'
        }
    }

    const Login = async (form: LoginForm) => {
        clearErrors()
        setError('')
        console.log(form)

        axios.post("/AjaxCalls.aspx",
            null,
            {
                params: {
                    IDUtente: form.Nome
                },
                headers: {
                    "x-psw": form.Password
                }
            }).then((res) => {
            if (res.data !== '') {
                setError(res.data)
            } else {
                window.location.replace(window.location.origin + '/Default.aspx')
            }
        }).catch((err) => {
            console.log(err)
            setError(err.message)
        })
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <div className="row">

                    <div className="column algnItmsCntr jstCntntCntr">

                        <div className={"card"}>
                            <img className={"logo"} src={logo}/>
                            <p className={"title algnSlfStrt"}>Accedi</p>
                            <Controller
                                control={control}
                                name={'Nome'}
                                render={({
                                    field: {onChange, onBlur, value},
                                    fieldState: {error},
                                }) =>

                                    <TextField
                                        error={!!error}
                                        helperText={error ? error?.message : null}
                                        onChange={onChange}
                                        value={value}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircleIcon/>
                                                </InputAdornment>
                                            )
                                        }}
                                        style={styles.input} label="Nome Utente" variant="standard"/>
                                }
                            />

                            <Controller
                                control={control}
                                name={'Password'}
                                render={({
                                    field: {onChange, onBlur, value},
                                    fieldState: {error},
                                }) =>
                                    <TextField
                                        error={!!error}
                                        helperText={error ? error?.message : null}
                                        onChange={onChange}
                                        value={value}
                                        type={showPassword ? 'password' : 'text'}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock/>
                                                </InputAdornment>
                                            ),
                                            endAdornment:
                                                (<InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>)

                                        }} style={styles.input} label="Password" variant="standard"/>}
                            />

                            <Button style={styles.button} className="button" variant="contained"
                                    onClick={handleSubmit(Login)}>Accedi</Button>

                            {error !== '' ? <p className={"error"}>{error}</p> : null}
                        </div>

                    </div>


                    <div className="column" id={"carousel-wrapper"}>
                        <Carousel className={"column"} autoPlay={true} interval={4000} infiniteLoop={true}
                                  showArrows={false} showThumbs={false} showStatus={false}>
                            <img className={"img"} src={img1}/>
                            <img className={"img"} src={img2}/>
                            <img className={"img"} src={img3}/>
                        </Carousel>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default App;
